<template>
  <v-row>
    <v-col cols="12">
      <v-row justify="center">
        <v-col cols="5">
          <mf-text-input v-model="user.name" :rules="[noEmptyRule]" label="Nome"></mf-text-input>
        </v-col>
        <v-col cols="5">
          <mf-text-input v-model="user.login" :rules="[loginRule]" label="Email" outlined @blur="setMail(user.login)"></mf-text-input>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-n6">
        <v-col class="ml-3" cols="3">
          <v-row justify="center">
            <v-col cols="auto">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <mf-toggle v-model="user.mf" label="Admin Plataforma" color="#334A58" value />
                  </div>
                </template>
                <span>Garante ao usuário acesso a todas as contas da plataforma. </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { UserInternal } from '@/modules/users/mixins/user.js'
export default {
  mixins: [UserInternal],
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    setMail(val) {
      if (!val.includes('@') && val !== '') {
        this.user.login = `${val}@mercafacil.com`
      }
    }
  }
}
</script>
